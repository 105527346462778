

import { defineComponent } from 'vue'
import Button from '@/components/Button.vue'

// Utils
import utilities from "../utilities";

export default defineComponent({
    name: "UserData",
    components:
    {
        Button
    },
    data(){
        return{
            editModeOn: false,
            user: [] as any[any],
            formFields: [
                {
                    label: 'Nombre',
                    type: 'text',
                    field: 'first_name'
                },
                {
                    label: 'Apellido',
                    type: 'text',
                    field: 'last_name',
                },
                {
                    label: 'Email',
                    type: 'email',
                    field: 'email',
                },
                {
                    label: 'Contraseña',
                    type: 'password',
                    field: 'password',
                }
            ]
        }
    },
    mounted(){
        //check auth and get token
        let checkAuth = utilities.checkAuth('mi-cuenta') as any
        Promise.resolve(checkAuth).then((data: any[any]) => {
            this.user = data
        })
    },
    methods:{
        editMode: function(){
            this.editModeOn = !this.editModeOn
        }
    }

})

